import React from "react";
import styled from "styled-components";

const HomeContainer = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeStatement = styled.h1`
  margin: 0;
  padding: 15vw 20vw;
  font-size: 4em;
  opacity: 0.80; /* Make the text slightly transparent */
  color: ${(props) => props.theme.textColor}; /* Ensure text color respects theme */

  @media (max-width: 768px) {
    padding: 10vw;
    font-size: 2.5em;
  }

  @media (max-width: 480px) {
    padding: 5vw;
    font-size: 2.5em;
  }
`;

const Home = () => {
  return (
    <HomeContainer id="home">
      <WelcomeStatement>
        <i>Hi</i>, I'm Christian Tapnio - an Information Systems Technician and Freelance Web Developer based in Vancouver, BC. Some of my current interests include networking, computer-aided design, and full-stack web development.
      </WelcomeStatement>
    </HomeContainer>
  );
};

export default Home;
