import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import styled from "styled-components"; // Import styled-components

// Importing logos
import malahatnation from "../assets/malahatnation.svg";
import mangovisa from "../assets/mangovisa.png";
import regionofpeel from "../assets/regionofpeel.jpeg";
import richmondolympicoval from "../assets/richmondolympicoval.svg";
import worldvisioncanada from "../assets/worldvisioncanada.svg";

// Custom styled component for TimelineOppositeContent with theme-based color
const StyledTimelineOppositeContent = styled(TimelineOppositeContent)`
  color: ${(props) => props.theme.textColor}; /* Using theme's text color */
  margin: auto 0;
  text-align: right; /* Align the content to the right */
`;

const WorkExperienceTimeline = () => {

  return (
    <Timeline position="alternate">
      {/* Malahat Nation Job */}
      <TimelineItem>
        <StyledTimelineOppositeContent>
        <br/>
          Jan 2024 - Present
        </StyledTimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <img src={malahatnation} alt="Malahat Nation" style={{ width: "30px", height: "30px" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            Information Systems Technician – Malahat Nation
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {/* Richmond Olympic Oval */}
      <TimelineItem>
        <StyledTimelineOppositeContent>
        <br/>
          Oct 2022 - Dec 2023
        </StyledTimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <img src={richmondolympicoval} alt="Richmond Olympic Oval" style={{ width: "30px", height: "30px" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            IT Support Specialist – Richmond Olympic Oval
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {/* MangoVisa */}
      <TimelineItem>
        <StyledTimelineOppositeContent>
        <br/>
          May 2022 - Sept 2022
        </StyledTimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <img src={mangovisa} alt="MangoVisa" style={{ width: "30px", height: "30px" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            Software Developer Intern – MangoVisa
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {/* World Vision Canada */}
      <TimelineItem>
        <StyledTimelineOppositeContent>
        <br/>
          Aug 2021 – Jan 2022
        </StyledTimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <img src={worldvisioncanada} alt="World Vision Canada" style={{ width: "30px", height: "30px" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            Research and Development Intern – World Vision Canada
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {/* Region of Peel */}
      <TimelineItem>
        <StyledTimelineOppositeContent>
            <br/>
          Dec 2020 - May 2021
        </StyledTimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <img src={regionofpeel} alt="Region of Peel" style={{ width: "30px", height: "30px" }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            IT Analyst (Co-op) – Region of Peel
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default WorkExperienceTimeline;
