import React from "react";
import styled from "styled-components";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from '@mui/icons-material/X';
import InstagramIcon from "@mui/icons-material/Instagram";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const Container = styled.nav`
  background-color: transparent;
  position: fixed;
  overflow-x: hidden;
  height: 100vh;
  width: 200px;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 0;

  @media (max-width: 768px) {
    height: auto;
    width: 100%;
    bottom: 0;
    top: auto;
  }
`;

const LinkContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 120%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    position: static;
    transform: none;
    top: auto;
    left: auto;
    bottom: 0;
    width: 100%;
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
  }
`;

const SocialLink = styled.a`
  background: none;
  text-decoration: none;
  color: ${(props) => props.theme.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;

  @media (max-width: 768px) {
    margin: 0 10px;
  }

  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:visited,
  &::after {
    background: none;
    text-decoration: none;
  }
`;

const ThemeToggleButton = styled.button`
  background-color: transparent; /* Remove background */
  color: ${(props) => (props.currentTheme === "light" ? "#000" : "#fff")}; /* Invert color based on theme */
  border: none; /* Remove border */
  padding: 5px; /* Remove padding */
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none; /* Remove outline */
  transition: color 0.3s ease; /* Smooth color transition */

  &:hover {
    color: ${(props) => (props.currentTheme === "light" ? "#333" : "#ddd")}; /* Subtle hover effect */
  }
`;

const Navbar = ({ toggleTheme, currentTheme }) => {
  return (
    <Container>
      <LinkContainer>
        <SocialLinksContainer>
          <SocialLink href="https://github.com/ctapnio" target="_blank">
            <GitHubIcon fontSize="small" />
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/in/ctapnio/" target="_blank">
            <LinkedInIcon fontSize="small" />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/ctaipan/" target="_blank">
            <InstagramIcon fontSize="small" />
          </SocialLink>
          <SocialLink href="https://twitter.com/ctapnio_" target="_blank">
            <XIcon fontSize="small" />
          </SocialLink>
          <ThemeToggleButton onClick={toggleTheme} currentTheme={currentTheme}>
            {currentTheme === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
          </ThemeToggleButton>
        </SocialLinksContainer>
      </LinkContainer>
    </Container>
  );
};

export default Navbar;
