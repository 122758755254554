import styled from "styled-components";
import { PopupButton } from "react-calendly";

const Container = styled.section`
  height: 100vh;
  scroll-snap-align: start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.textColor}; /* Adjust title color based on the current theme */
`;

const EmailButton = styled.button`
  background-color: ${(props) => props.theme.buttonBackground}; /* Adjust button background */
  color: ${(props) => props.theme.buttonColor}; /* Adjust button text color */
  cursor: pointer;
  width: 100%;
  font-family: inherit;
  padding: 0.6em 1.3em;
  font-size: 18px;
  border-radius: 2em;
  border: none;
`;

const EmailLink = styled.a`
  text-decoration: none;
  width: 100%;
  font-size: 2em;
  padding-bottom: 2.5%;
`;

const StyledPopupButton = styled(PopupButton)`
  background-color: ${(props) => props.theme.buttonBackground}; /* Adjust background */
  color: ${(props) => props.theme.buttonColor}; /* Adjust text color */
  cursor: pointer;
  width: 100%;
  font-family: inherit;
  padding: 0.6em 1.3em;
  font-size: 1em;
  border-radius: 2em;
  border: none;
  text-align: center; /* Center text inside the button */
`;

const ContactWrapper = styled.div`
  width: 25%;
  display: flex;
  transition: transform ease;
  overflow: hidden;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    align-items: center;
    height: 40%;
    width: 50%;
  }
`;

const Footer = styled.p`
  position: absolute;
  bottom: 0;
  font-size: 1em;
  margin: 0;
  text-align: center;
  width: 100%;
  color: ${(props) => props.theme.textColor}; /* Adjust footer text color based on the theme */
`;

const Contact = () => {
  return (
    <Container id="contact">
      <Title>Contact</Title>
      <ContactWrapper>
        <EmailLink href="mailto: tapnioc@sheridancollege.ca">
          <EmailButton>Email</EmailButton>
        </EmailLink>
        <StyledPopupButton
          url="https://calendly.com/tapnioc/30min"
          rootElement={document.getElementById("root")}
          text="Virtual Meeting"
        />
      </ContactWrapper>
      <Footer>Designed & developed by Christian Tapnio. ©</Footer>
    </Container>
  );
};

export default Contact;
