import styled from "styled-components";
import profPic from "../assets/profile.jpg";
import WorkExperienceTimeline from "./WorkExperienceTimeline"; // Import your new timeline component

const Container = styled.section`
  min-height: 100vh; /* Use min-height instead of height for flexibility */
  scroll-snap-align: start;
  display: flex;
  flex-direction: row; /* Side-by-side layout */
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;
  text-decoration: none;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding-bottom: 4rem; /* Add some padding to avoid overlap with the next section */
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const AboutPic = styled.img`
  margin-bottom: 1rem;
  height: 4em;
  border-radius: 50%;
  opacity: 80%;

  @media (max-width: 768px) {
    align-self: center;
  }
`;

const Blockquote = styled.blockquote`
  margin: 0;
  padding: 0 2vw;
  font-size: 1.3em;
  font-weight: 400;
  text-align: left;
  opacity: 0.85;
  color: ${(props) => props.theme.textColor};
  border-left: 4px solid ${(props) => props.theme.buttonBackground};
  padding-left: 1rem;
  line-height: 1.6;

  @media (max-width: 768px) {
    padding: 0 10vw;
    font-size: 2vh;
  }

  @media (max-width: 480px) {
    padding: 0 5vw;
    font-size: 2vh;
  }
`;

const About = () => {
  return (
    <Container id="about">
      <LeftSection>
        <AboutPic src={profPic} alt="Profile Picture" />
        <Blockquote>
          I started my career as a Mechanical Technician, gaining hands-on
          experience with automation and machinery. This experience ignited my
          passion for automating processes and building impactful software. In
          2019, I shifted to software development, where I’ve embraced new
          technologies and collaborated with teams on innovative projects. I am
          committed to leveraging my technical expertise to develop solutions
          that enhance user experiences and drive meaningful impact.
        </Blockquote>
      </LeftSection>
      <RightSection>
        <WorkExperienceTimeline />
      </RightSection>
    </Container>
  );
};

export default About;
