import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "../ghost-api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode } from "swiper/modules"; // Only keeping Pagination and FreeMode modules
import "swiper/css";
import "swiper/css/pagination"; // Import pagination styles
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const StyledSwiper = styled(Swiper)`
  width: 50%;
  height: 50%;
  padding: 5%;
  @media (max-width: 768px) {
    width: 70%;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 30px;
  background-color: #1a1a1a;
  color: #ffffff;

  @media (max-width: 768px) {
    border-radius: 10px;
  }
`;

const SlideImage = styled.div`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  opacity: 1;
  filter: brightness(50%) blur(1px);
  border-radius: 30px;

  @media (max-width: 768px) {
    border-radius: 10px;
  }
`;

const SlideTitle = styled.div`
  font-size: 35px;
  font-weight: 300;
  position: absolute;
  z-index: 10;
  color: white;
`;

const SlideDate = styled.div`
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  color: white;
`;

const Container = styled.section`
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.textColor};
  font-size: 2.5rem;
  text-align: center;
  margin: 20px 0;
`;

const ProjectTitle = styled.h1`
  color: #ffffff !important;
`;

const getModalStyle = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 600 ? "80vw" : "600px",
  maxHeight: "80vh",
  bgcolor: "#1a1a1a",
  color: "#ffffff",
  border: "none",
  p: 4,
  overflowY: "auto",
  borderRadius: "10px",
});

const GhostPostContent = styled.div`
  color: #ffffff !important;
  font-size: 16px !important;
  line-height: 1.6 !important;
  overflow-y: scroll;
  max-height: 500px;

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

const Projects = () => {
  const [posts, setPosts] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [modalStyle, setModalStyle] = useState(getModalStyle("light"));
  const [slidesPerView, setSlidesPerView] = useState(
    window.innerWidth < 600 ? 1 : 2
  );

  const handleOpen = (post) => {
    setCurrentPost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentPost(null);
    setOpen(false);
  };

  useEffect(() => {
    const tagFilter = 'Project'; 

    api.posts
      .browse({ limit: 5, include: "tags,authors", filter: `tag:${tagFilter}` })
      .then((posts) => {
        setPosts(posts);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setModalStyle(getModalStyle());
      setSlidesPerView(window.innerWidth < 600 ? 1 : 2);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container id="projects">
      <Title>Work / Projects</Title>
      <StyledSwiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        freeMode={true}
        pagination={{ clickable: true }} // Keep only pagination
        modules={[FreeMode, Pagination]} // Removed Navigation module
      >
        {posts.map((post) => (
          <StyledSwiperSlide key={post.id} onClick={() => handleOpen(post)}>
            <SlideImage image={post.feature_image} />
            <SlideTitle>{post.title}</SlideTitle>
            <SlideDate>{new Date(post.published_at).toLocaleDateString()}</SlideDate>
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {currentPost && (
            <div>
              <ProjectTitle>{currentPost.title}</ProjectTitle>
              <GhostPostContent dangerouslySetInnerHTML={{ __html: currentPost.html }} />
            </div>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default Projects;
