// Technologies.js
import React from "react";
import styled from "styled-components";
import { FaReact, FaPython, FaDocker, FaAws, FaJava, FaMicrosoft } from "react-icons/fa";
import { SiNextdotjs, SiDjango, SiSpringboot, SiMysql, SiMicrosoftazure, SiWordpress } from "react-icons/si";

const TechnologiesContainer = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.theme.textColor};
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.textColor};

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
  justify-items: center;
  width: 80%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease;

  svg {
    width: 4rem;
    height: 4rem;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.2);
    }

    @media (max-width: 768px) {
      width: 3rem;
      height: 3rem;
    }

    @media (max-width: 480px) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &:hover span {
    opacity: 1;
    transform: translateY(10px);
  }
`;

const IconLabel = styled.span`
  position: absolute;
  top: 3.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.textColor};
  opacity: 0;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    top: 3.5rem;
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    top: 3rem;
    font-size: 0.75rem;
  }
`;

const Technologies = () => {
  return (
    <TechnologiesContainer id="technologies">
      <Title>Skills & Technologies</Title>
      <IconGrid>
        <IconWrapper>
          <FaReact color="#61DAFB" />
          <IconLabel>React</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <SiNextdotjs color="#000000" />
          <IconLabel>Next.js</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <SiDjango color="#092E20" />
          <IconLabel>Django</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <SiSpringboot color="#6DB33F" />
          <IconLabel>Spring Boot</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <SiMysql color="#4479A1" />
          <IconLabel>MySQL</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <FaDocker color="#2496ED" />
          <IconLabel>Docker</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <FaMicrosoft color="#F25022" />
          <IconLabel>Microsoft</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <SiMicrosoftazure color="#0089D6" />
          <IconLabel>Azure</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <FaAws color="#FF9900" />
          <IconLabel>AWS</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <FaPython color="#3776AB" />
          <IconLabel>Python</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <FaJava color="#007396" />
          <IconLabel>Java</IconLabel>
        </IconWrapper>
        <IconWrapper>
          <SiWordpress color="#21759B" />
          <IconLabel>WordPress</IconLabel>
        </IconWrapper>
      </IconGrid>
    </TechnologiesContainer>
  );
};

export default Technologies;
