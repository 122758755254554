import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import Navbar from "./components/navbar";
import Home from "./components/home";
import About from "./components/about";
import Projects from "./components/projects";
import Contact from "./components/contact";
import BackgroundLayer from "./components/BackgroundLayer";
import moonPic from "./assets/moonPic.png";
import treePic from "./assets/treePic.png";
import vancouverPic from "./assets/vancouverPic.png";
import SkillsTechnologies from './components/Technologies';

// Define light and dark themes
const lightTheme = {
  background: "#ffffff",
  textColor: "#000000",
  buttonBackground: "#272b36",
  buttonColor: "#ffffff",
};

const darkTheme = {
  background: "#272b36",
  textColor: "#ffffff",
  buttonBackground: "#ffffff",
  buttonColor: "#272b36",
};

// Global styles that will apply the current theme
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.textColor};
    transition: all 0.3s ease-in-out;
  }
`;

// Styled component for the first gradient background (J-pattern)
const GradientBackground = styled.div`
  position: fixed;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  width: 400vw;
  height: 400vh;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.8), transparent 60%);
  transform: translate(-50%, -50%);
  transition: top 0.2s ease, left 0.2s ease; 
  z-index: -1;
  opacity: 0.5;
`;

// Styled component for the second gradient background (moves towards bottom center)
const GradientBackgroundOpposite = styled.div`
  position: fixed;
  top: ${(props) => props.top}%;
  left: 50%; /* Keep the second gradient centered horizontally */
  width: 400vw;
  height: 400vh;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.5), transparent 60%);
  transform: translate(-50%, -50%);
  transition: top 0.2s ease;
  z-index: -2;
  opacity: 0.3;
`;

const SectionContainer = styled.div`
  position: relative;
  min-height: 110vh; /* Add a little extra height */
  padding-bottom: 4rem; /* Ensure there's enough bottom space to avoid overlap */

  @media (max-width: 768px) {
    min-height: 120vh; /* Add more height for mobile view */
  }
`;

const App = () => {
  const [theme, setTheme] = useState(() =>
    window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
  );
  const [gradientPosition, setGradientPosition] = useState({ top: 0, left: 100 });
  const [gradientPositionOpposite, setGradientPositionOpposite] = useState({ top: 100 });

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  // Handle scroll to move the gradients
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = scrollTop / scrollHeight;

    let newTop = scrollPercentage * 100;
    let newLeft;

    // First gradient: J-pattern
    if (scrollPercentage < 0.5) {
      newLeft = 100; // Stay on the right side
    } else {
      newLeft = 100 - (scrollPercentage - 0.5) * 200; // Move left as you scroll down
    }
    setGradientPosition({ top: newTop, left: newLeft });

    // Second gradient: Move towards bottom center
    let oppositeTop = 100 - newTop; // Inverse the vertical position to move towards bottom
    setGradientPositionOpposite({ top: oppositeTop });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <GlobalStyle />
      {/* Both gradient backgrounds */}
      <GradientBackground top={gradientPosition.top} left={gradientPosition.left} />
      <GradientBackgroundOpposite top={gradientPositionOpposite.top} />

      <Navbar toggleTheme={toggleTheme} currentTheme={theme} />

      {/* Home Section with Moon Background Layer */}
      <SectionContainer>
        <BackgroundLayer theme={theme} image={moonPic} size="70%" position="left center" opacity={0.5} />
        <Home />
      </SectionContainer>

      {/* About Section with Tree Background Layer */}
      <SectionContainer>
        <BackgroundLayer theme={theme} image={treePic} size="100%" position="right" />
        <About />
      </SectionContainer>

      {/* Projects Section */}
      <SectionContainer>
        <Projects />
      </SectionContainer>

      {/* Technologies Section */}
      <SectionContainer>
        <SkillsTechnologies />
      </SectionContainer>

      {/* Contact Section with Vancouver Background Layer */}
      <SectionContainer>
        <BackgroundLayer theme={theme} image={vancouverPic} size="contain" position="center bottom" />
        <Contact />
      </SectionContainer>
    </ThemeProvider>
  );
};

export default App;
