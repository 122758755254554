import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moonPic from "../assets/moonPic.png";
import treePic from "../assets/treePic.png";
// import vancouverPic from "../assets/vancouverPic.png"; // Import vancouverPic

// Define the styled component for the background layer
const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain; /* Ensure the entire image fits without being cropped */
  background-repeat: no-repeat;
  background-position: ${(props) => props.position || "center bottom"}; /* Position the image */
  background-image: url(${(props) => props.image});
  opacity: ${(props) => props.opacity !== undefined ? props.opacity : (props.theme === "light" ? 0.5 : 1)}; /* Use provided opacity, otherwise fall back to default */
  transform: ${(props) =>
    props.image === moonPic
      ? `translateX(${props.scrollPosition * -0.3}px)` /* Move moon right to left */
      : props.image === treePic
      ? `translateY(${props.scrollPosition * -0.2}px)` /* Move tree up and down */
      : "none"}; /* Vancouver image stays in place */
  transition: transform 0.1s ease-out;
  z-index: -1; /* Ensure it's behind the content */
`;

const BackgroundLayer = ({ image = moonPic, opacity, theme, size, position }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  // Update scroll position on scroll
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BackgroundImage
      image={image}
      opacity={opacity} // Use custom opacity if provided
      scrollPosition={scrollPosition}
      theme={theme}
      size={size}
      position={position}
    />
  );
};

export default BackgroundLayer;
