import GhostContentAPI from "@tryghost/content-api";

// Ensure the environment variables are defined
const apiUrl = process.env.REACT_APP_GHOST_API_URL;
const apiKey = process.env.REACT_APP_GHOST_API_KEY;
const apiVersion = process.env.REACT_APP_GHOST_API_VERSION;

if (!apiUrl || !apiKey || !apiVersion) {
  console.error("Ghost API configuration is missing. Please set REACT_APP_GHOST_API_URL, REACT_APP_GHOST_API_KEY, and REACT_APP_GHOST_API_VERSION environment variables.");
  throw new Error("Ghost API configuration is missing.");
}

const api = new GhostContentAPI({
  url: apiUrl,
  key: apiKey,
  version: apiVersion,
});

export default api;
